import { set } from "date-fns";
import React from "react";
import { createContainer } from "unstated-next";

const useGlobalContainer = createContainer(() => {
  const [mediaLibUrl, setMediaLibUrl] = React.useState(null);
  const [notificationReceived, setNotificationReceived] = React.useState(null);

  return {
    mediaLibUrl,
    setMediaLibUrl,
    notificationReceived,
    setNotificationReceived,
  };
});

export default useGlobalContainer;

export const withGlobalContainer = (Component) => (props) => {
  return (
    <useGlobalContainer.Provider>
      <Component {...props} />
    </useGlobalContainer.Provider>
  );
};
