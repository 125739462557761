import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Input,
  Button,
  Stack,
  Typography,
  Card,
  CircularProgress,
} from "@mui/joy";
import ChatMessage from "models/chatMessage";
import Helpers from "utils/Helpers";

/**
 * Composant réutilisable pour afficher et envoyer des messages dans un chat existant.
 * @param {string} userId - ID de l'utilisateur connecté (Admin ou utilisateur).
 * @param {string} userName - Nom de l'utilisateur connecté.
 * @param {string} projectId - ID du projet lié au chat.
 */
const ChatMessages = ({ userId, userName, projectId }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const messageEndRef = useRef(null);
  const convertDate = (d) => Helpers.fbDate(d, "DD/MM/yyyy HH:mm:ss");

  const chatMessageService = new ChatMessage({ projectId });

  useEffect(() => {
    if (!projectId) {
      console.log(
        "projectId est indéfini, impossible de charger les messages."
      );
      return;
    }

    console.log("📩 Chargement des messages pour le projet :", projectId);

    // Écoute des messages en temps réel
    const unsubscribe = chatMessageService.getMessages((newMessages) => {
      setMessages(newMessages);
      setLoading(false);
      scrollToBottom();
    });

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [projectId]);

  /**
   * Fonction pour scroller en bas automatiquement lorsqu'un nouveau message est ajouté.
   */
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * Gestion de l'envoi des messages.
   */
  const handleSendMessage = async () => {
    if (message.trim() === "") return;

    const newMessage = new ChatMessage({
      projectId,
      senderId: userId,
      senderName: userName,
      text: message,
    });

    await newMessage.sendMessage();
    setMessage("");
    scrollToBottom();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {/* Liste des messages (ordre inversé) */}
      <Box
        sx={{
          maxHeight: 400,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse", // Inverse l'ordre d'affichage
          gap: 1,
          padding: 1,
          borderRadius: "sm",
        }}
      >
        <div ref={messageEndRef} />
        {messages.length > 0 ? (
          messages.map((msg) => (
            <Stack
              key={msg.id}
              direction="row"
              spacing={1}
              sx={{
                justifyContent:
                  msg.senderId === userId ? "flex-end" : "flex-start",
              }}
            >
              <Box
                sx={{
                  maxWidth: "75%",
                  padding: 1,
                  backgroundColor:
                    msg.senderId === userId
                      ? "primary.softBg"
                      : "neutral.outlinedBorder",
                  borderRadius: "lg",
                  textAlign: msg.senderId === userId ? "right" : "left",
                }}
              >
                <Typography
                  level="caption"
                  sx={{ fontSize: "10px", color: "#888" }}
                >
                  {convertDate(msg.created_at)}
                </Typography>
                <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                  {msg.senderId === userId ? "Moi" : msg.senderName}
                </Typography>
                <Typography level="body-sm">{msg.text}</Typography>
              </Box>
            </Stack>
          ))
        ) : (
          <Typography
            level="body-sm"
            sx={{ textAlign: "center", color: "neutral.outlinedColor" }}
          >
            Aucun message pour le moment...
          </Typography>
        )}
      </Box>

      {/* Zone de saisie */}
      <Stack
        direction="row"
        spacing={1}
        sx={{ marginTop: 2, backgroundColor: "#FFF", p: 2 }}
      >
        <Input
          placeholder="Écrire un message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button onClick={handleSendMessage} variant="solid">
          Envoyer
        </Button>
      </Stack>
    </Box>
  );
};

export default ChatMessages;
