import FirestoreService from "services/firestoreServices";
import Helpers from "utils/Helpers";
import { where, orderBy } from "firebase/firestore";
import Activities from "models/activities";
import Planning from "models/plannings";
import Meals from "models/meals";

/**
 * Creates an instance of the Project class.
 * @constructor
 * @param {Object} params - The constructor parameters.
 * @param {string} params.project_name - The name of the project.
 * @param {} params.project_event_date_period - 
 * @param {} params.project_event_day_duration - 
 * @param {} params.project_event_participants_qty - 
 * @param {} params.weever - weever en charge du projet 
 * @param {} params.event_budget - 
 * @param {} params.event_budget_type - 
 * @param {} params.project_event_type - 
 * @param {Array} params.project_places - TAbleau contenant les lieu [{id,nom,ville,code_postale,pays}]
 * @param {} params.project_details - 
 * @param {} params.project_event_budget - 
 * @param {Array} params.event_programs - Tableau d'objet contenant les différents programme lié à un projet
 * @param {} params.project_event_budget_type - 
 * @param {} params.project_event_date_stopped - 
 * @param {} params.project_event_objective - 
 * @param {} params.project_event_start_date - 
 * @param {} params.project_event_end_date - 
 * @param {Array} params.project_animations - 
 * @param {} params.project_event_seminar_type - 
 * @param {} params.project_documents - 
 * @param {} params.uid - 
 * @param {} params.active - 
 * @param {} params.created_at - 

 */

class Project {
  constructor(params) {
    Object.assign(this, params);
    this.modified_at = new Date();

    this.firestoreService = new FirestoreService("projects");
  }

  async getProject(id) {
    try {
      const project = await this.firestoreService.getDocument(id);
      return project;
    } catch (error) {
      console.error("Failed to fetch project:", error);
      throw error;
    }
  }

  async filterProjects(filters, lastVisible = null) {
    try {
      const queryConstraints = [];

      // Filtrer par uid
      if (filters.uid) {
        queryConstraints.push(where("uid", "==", filters.uid));
      }

      // Filtrer par status
      if (filters.status) {
        queryConstraints.push(where("status", "==", filters.status));
      }

      // Filtrer par project_event_start_date
      if (filters.project_event_start_date) {
        queryConstraints.push(
          where(
            "project_event_start_date",
            "<=",
            filters.project_event_start_date
          )
        );
      }
      if (filters.project_event_start_date) {
        queryConstraints.push(orderBy("project_event_start_date", "desc"));
      } else if (!queryConstraints.some((c) => c.type === "orderBy")) {
        queryConstraints.push(orderBy("created_at", "desc"));
      }
      const result = await this.firestoreService.getDocuments(
        queryConstraints,
        false,
        null,
        10,
        lastVisible
      );
      if (lastVisible) {
        return {
          documents: result.documents,
          lastVisible: result.lastVisible,
          no_more_data: result.no_more_data,
        };
      } else {
        return result;
      }
    } catch (error) {
      console.error("Failed to filter projects:", error);
      throw error;
    }
  }

  async searchProjectByName(searchTerm, uid) {
    try {
      const queryConstraints = [
        where("project_name", ">=", searchTerm),
        where("project_name", "<=", searchTerm + "\uf8ff"),
        where("uid", "==", uid),
        orderBy("project_name"),
      ];

      const result = await this.firestoreService.getDocuments(
        queryConstraints,
        false,
        null,
        10
      ); // Limité à 5 résultats
      return result.documents; // Renvoie les groupes trouvés
    } catch (error) {
      console.error("Failed to fetch project_name by name:", error);
      throw error;
    }
  }

  async getProjects(lastVisible = null, constraints = []) {
    try {
      const result = await this.firestoreService.getDocuments(
        constraints,
        false,
        null,
        20,
        lastVisible
      );
      if (lastVisible) {
        return {
          documents: result.documents,
          lastVisible: result.lastVisible,
          no_more_data: result.no_more_data,
        };
      } else {
        return result;
      }
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      throw error;
    }
  }
  async getProjectsByUid(uid) {
    try {
      const clauses = [where("uid", "==", uid)];
      clauses.push(orderBy("created_at", "desc"));

      const projects = await this.firestoreService.getDocuments(clauses);
      if (projects && projects.length > 0) {
        return projects;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to fetch project:", error);
      throw error;
    }
  }

  async addProject() {
    try {
      const project = await this.firestoreService.addDocument(this.toJSON());
      return { id: project.id, ...this.toJSON() };
    } catch (error) {
      console.error("Failed to add project:", error);
      throw error;
    }
  }

  async deleteProject(id) {
    try {
      await this.firestoreService.deleteDocument(id);
    } catch (error) {
      console.error("Failed to delete project:", error);
      throw error;
    }
  }

  async updateProject(id) {
    try {
      const updatedProject = await this.firestoreService.updateDocument(
        id,
        this.toJSON(),
        []
      );
      return updatedProject;
    } catch (error) {
      console.error("Failed to update project:", error);
      throw error;
    }
  }

  toJSON() {
    let data = {};
    const properties = {
      uid: this.uid,
      status: this.status,
      project_event_date_period: this.project_event_date_period,
      project_event_day_duration: this.project_event_day_duration,
      project_name: this.project_name,
      project_event_participants_qty: this.project_event_participants_qty,
      event_budget: this.event_budget,
      event_budget_type: this.event_budget_type,
      project_event_type: this.project_event_type,
      event_programs: this.event_programs,
      project_animations: this.project_animations,
      project_team_buildings: this.project_team_buildings,
      project_details: this.project_details,
      project_event_budget: this.project_event_budget,
      project_event_budget_type: this.project_event_budget_type,
      project_event_objective: this.project_event_objective,
      project_places: this.project_places,
      project_documents: this.project_documents,
      project_event_date_stopped: this.project_event_date_stopped,
      weever: this.weever,
      project_event_start_date: this.project_event_start_date,
      project_event_end_date: this.project_event_end_date,
      project_event_seminar_type: this.project_event_seminar_type,
      modified_at: this.modified_at,
      created_at: this.created_at,
    };

    // Add only properties that are not undefined
    for (let key in properties) {
      if (properties[key] !== undefined) {
        data[key] = properties[key];
      }
    }

    return data;
  }
}

export default Project;
