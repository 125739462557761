import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  List,
  ListItem,
  Avatar,
  Button,
  Stack,
} from "@mui/joy";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Chat from "models/chats";
import Helpers from "utils/Helpers";
import useAuthContainer from "stores/Auth";

import { ChatMessages, WhiteContainer } from "UI";

const ChatEntity = new Chat();
const dateFormater = (d) => Helpers.fbDate(d, `DD/MM/YYYY HH:mm`);

const ConverationItem = ({ conversation, projectID, setProjectID }) => {
  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        p: 2,
        columnGap: "10px",

        display: "flex",
        backgroundColor:
          projectID === conversation?.projectId ? "#f5f5f5" : "#FFF",
        "&:hover": {
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
        },
      }}
      onClick={() => setProjectID(conversation?.projectId)}
    >
      <Box sx={{ display: "flex", rowGap: "10px", flexDirection: "column" }}>
        <Box
          sx={{
            columnGap: "10px",
            display: "flex",
            alignItems: "center",
            rowGap: "10px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "180px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontWeight: "bold",
            }}
          >
            {conversation?.projectName}
          </Typography>
          <Typography sx={{ fontSize: "10px" }}>
            {dateFormater(conversation?.lastMessageTimestamp) === "Invalid date"
              ? ""
              : dateFormater(conversation?.lastMessageTimestamp)}
          </Typography>
        </Box>
        <Typography variant="h6">{conversation?.lastMessage}</Typography>
      </Box>
    </Box>
  );
};

const Messages = (props) => {
  const { user } = useAuthContainer.useContainer();
  const { t } = useTranslation();
  const [conversations, setConversations] = React.useState([]);
  const [projectID, setProjectID] = React.useState(null);

  console.log(conversations);
  const fetchChats = async () => {
    try {
      const chats = await ChatEntity.getAllChats((chats) =>
        setConversations(chats)
      );
    } catch (e) {
      console.error("fetching chats error", e.message);
    }
  };

  React.useEffect(() => {
    fetchChats();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "300px", overflow: "auto", height: "600px" }}>
        {conversations.map((conversation, index) => (
          <ConverationItem
            key={index}
            projectID={projectID}
            setProjectID={setProjectID}
            conversation={conversation}
          />
        ))}
      </Box>
      <Box sx={{ flexGrow: "1", backgroundColor: "#f5f5f5" }}>
        {projectID && (
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ p: 2, background: "#FFF", width: "100%" }}
          >
            <Button component={Link} to={`/projects/${projectID}/dashboard`}>
              {t("show_project")}
            </Button>
          </Stack>
        )}
        <ChatMessages
          userId={user?.uid}
          userName="Administrateur"
          projectId={projectID}
        />
      </Box>
    </Box>
  );
};

export default Messages;
Messages.propTypes = {};
Messages.defaultProps = {};
