import React from "react";
import { useTranslation } from "react-i18next";
import { Box, ListItem, Typography, List } from "@mui/joy";
import PropTypes from "prop-types";
import useAuthContainer from "stores/Auth";
import { AutocompleteSimple } from "UI";
import User from "models/user";
import { useForm } from "react-hook-form";
import { where } from "firebase/firestore";
import { set } from "date-fns";
import Project from "models/projects";
import { useParams } from "react-router-dom";

const UserInstance = new User();
const ProjectEntity = new Project();

const AddWeeverSection = ({ weever, setDatas }) => {
  const { projectID } = useParams();
  const { t } = useTranslation();
  const { setValue } = useForm();
  const [foundedUsers, setFoundedUsers] = React.useState([]);
  const { userAccount } = useAuthContainer.useContainer();
  const isAdmin = userAccount?.role === "admin";

  const fetchUsers = async (searchTerm) => {
    setFoundedUsers([]);
    if (!searchTerm) return [];

    const result = await UserInstance.searchUsersByName(searchTerm, [
      where("role", "==", "admin"),
    ]);

    setFoundedUsers(result);

    return result.map((user) => ({
      label: `${user.email}`,
      value: user.id,
    }));
  };

  const handleSelectWeever = (value) => {
    if (value) {
      setValue("uid", value.value);
    } else {
      setValue("uid", null);
    }

    const weever = foundedUsers.find((user) => user.id === value?.value);
    console.log(weever);
    if (weever) {
      const weeverToAdd = {
        uid: weever.id,
        email: weever.email,
        lastname: weever?.additionalDatas?.lastname || null,
        name: weever?.additionalDatas?.name || null,
      };
      ProjectEntity.weever = weeverToAdd;

      ProjectEntity.updateProject(projectID);
      setDatas({ weever: weeverToAdd });
    }
  };

  return isAdmin ? (
    <>
      {weever && (
        <Box>
          <List>
            <ListItem>
              <Typography>{t("email")} : </Typography>
              <Typography>{weever?.email}</Typography>
            </ListItem>
            <ListItem>
              <Typography>{t("name")} : </Typography>
              <Typography>{weever?.name}</Typography>
            </ListItem>
            <ListItem>
              <Typography>{t("lastname")} : </Typography>
              <Typography>{weever?.lastname}</Typography>
            </ListItem>
          </List>
        </Box>
      )}
      <AutocompleteSimple
        placeholder={t("select_a_weever")}
        label={t("select_a_weever")}
        fetchOptions={fetchUsers}
        onSelect={(value) => {
          handleSelectWeever(value);
        }}
      />{" "}
    </>
  ) : (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>
        {weever?.name} {weever?.lastname}{" "}
      </Typography>
    </Box>
  );
};

export default AddWeeverSection;
AddWeeverSection.propTypes = {};
AddWeeverSection.defaultProps = {};
