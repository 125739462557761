import React from "react";
import { createContainer } from "unstated-next";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import {
  db,
  auth,
  requestNotificationPermission,
  onForegroundNotification,
} from "../utils/MyFirebase";

const useAuthContainer = createContainer(() => {
  const [user, setUser] = React.useState(false);
  const [userAccount, setUserAccount] = React.useState(null);
  const [credentials, setCredentials] = React.useState();
  const [claims, setClaims] = React.useState();
  const [mediaLibUrl, setMediaLibUrl] = React.useState(null);
  const [lang, setLang] = React.useState(process.env.REACT_APP_default_lang);
  const [notification, setNotification] = React.useState(null); // État pour la notification

  React.useEffect(() => {
    if (user && userAccount) {
      // Activer les notifications et écouter les messages au premier plan
      const enableNotifications = async () => {
        try {
          await requestNotificationPermission(user.uid);

          onForegroundNotification((payload) => {
            setNotification(payload); // Met à jour l'état avec la notification reçue
            setTimeout(() => {
              setNotification(null);
            }, 3500);
          });
        } catch (error) {
          console.error(
            "Erreur lors de l'activation des notifications :",
            error
          );
        }
      };

      enableNotifications();
    }
  }, [user, userAccount]);

  React.useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u);

        onSnapshot(
          doc(db, "/users", u.uid),
          (doc) => {
            if (doc.exists()) {
              auth.currentUser.getIdTokenResult(true).then((tr) => {
                setClaims(tr.claims);
              });
              setUserAccount(doc.data());
              const { default_lang } = doc.data();
              if (default_lang) {
                setLang(default_lang);
              }
            }
          },
          (e) => {
            console.log("login issue==>", e.message);
            setUserAccount(null);
          }
        );
      } else {
        setUser(false);
      }
    });
  }, []);

  return {
    user,
    userAccount,
    lang,
    claims,
    mediaLibUrl,
    setMediaLibUrl,
    setCredentials,
    credentials,
    notification, // Expose l'état de la notification
  };
});

export default useAuthContainer;

export const withAuthContainer = (Component) => (props) => {
  return (
    <useAuthContainer.Provider>
      <Component {...props} />
    </useAuthContainer.Provider>
  );
};
