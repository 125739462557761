import FirestoreService from "services/firestoreServices";
import { serverTimestamp, orderBy } from "firebase/firestore";

class ChatMessage {
  constructor(params) {
    Object.assign(this, params);
    this.created_at = this.created_at || serverTimestamp();
    this.modified_at = serverTimestamp();
    this.firestoreService = new FirestoreService(
      `chats/${params.projectId}/messages`
    );
  }

  /**
   * Récupérer les messages d'un chat en temps réel (ordre décroissant)
   */
  async getMessages(onUpdate) {
    try {
      if (!this.projectId) {
        throw new Error(
          "projectId est indéfini lors de la récupération des messages"
        );
      }

      return await this.firestoreService.getDocuments(
        [orderBy("created_at", "desc")], // Charger les plus récents en premier
        true, // Activer l'écoute en temps réel
        onUpdate,
        50 // Charger max 50 messages
      );
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      throw error;
    }
  }

  /**
   * Envoyer un message dans un chat
   */
  async sendMessage() {
    try {
      if (!this.projectId) {
        throw new Error("projectId est indéfini lors de l'envoi du message");
      }

      const message = await this.firestoreService.addDocument(this.toJSON());

      // Vérification de projectId avant mise à jour du chat
      if (this.projectId) {
        const chatService = new FirestoreService("chats");
        await chatService.updateDocument(this.projectId, {
          lastMessage: this.text,
          lastMessageTimestamp: serverTimestamp(),
        });
      } else {
        console.error(
          "Impossible de mettre à jour le chat : projectId est indéfini"
        );
      }

      return { id: message.id, ...this.toJSON() };
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      throw error;
    }
  }

  /**
   * Supprimer un message
   */
  async deleteMessage(id) {
    try {
      await this.firestoreService.deleteDocument(id);
    } catch (error) {
      console.error("Failed to delete message:", error);
      throw error;
    }
  }

  /**
   * Convertir l'objet Message en JSON (seulement les champs définis)
   */

  toJSON() {
    const data = {};
    const properties = {
      senderId: this.senderId,
      senderName: this.senderName,
      text: this.text,
      created_at: this.created_at || serverTimestamp(),
      modified_at: this.modified_at || serverTimestamp(),
    };

    // Add only properties that are not undefined
    for (let key in properties) {
      if (properties[key] !== undefined) {
        data[key] = properties[key];
      }
    }

    return data;
  }
}

export default ChatMessage;
