import { initializeApp } from "firebase/app";
import {
  arrayUnion,
  enableIndexedDbPersistence,
  getFirestore,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import User from "models/user";
import useGlobalContainer from "stores/Global";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const emulators = false;
const MyFirebase = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions();
const analytics = getAnalytics();
const storage = getStorage();
const messaging = getMessaging();

if (emulators) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// enable persistence .
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    /* alert(
      'Your app is already open in another tab  , cache might be not handle in this tab '
    )*/
  } else if (err.code === "unimplemented") {
    /*alert(
      'Your browser doesnt support offline mode , for better experience use a modern browser'
    )*/
  }
});

/**
 * Demande la permission de recevoir des notifications push et récupère le token.
 * @returns {string} Token FCM
 */

let isRequestingPermission = false;

const requestNotificationPermission = async (uid) => {
  if (isRequestingPermission) {
    console.warn("Permission déjà en cours de demande.");
    return;
  }

  isRequestingPermission = true;

  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BPVs_lfPFSWp-0uRvt4YM-1LewHDvYhvYvkcf1L1ux2gPNs_n_Q5t7iKmDyhJxdSfDhmsW2AofKSbwY3konyVWk",
    });

    if (token) {
      const userInstance = new User();
      userInstance.fcmTokens = arrayUnion(token);
      userInstance.modified_at = undefined;
      await userInstance.updateUser(uid);
      console.log("Token enregistré :", token);
      return token;
    } else {
      console.warn("Pas de token reçu. Les notifications sont désactivées.");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la demande de permission :", error);
    return null;
  } finally {
    isRequestingPermission = false;
  }
};

/**
 * Configure un listener pour recevoir les notifications en premier plan.
 */
const onForegroundNotification = (callback) => {
  onMessage(messaging, (payload) => {
    if (callback) {
      callback(payload); // Passer la notification à la fonction de rappel
    }

    console.log("Foreground notification received:", payload);
  });
};

export {
  MyFirebase,
  db,
  auth,
  functions,
  analytics,
  storage,
  messaging,
  requestNotificationPermission,
  onForegroundNotification,
};
