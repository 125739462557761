import {
  getDoc,
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  writeBatch,
  setDoc,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "utils/MyFirebase";

class FirestoreService {
  constructor(collectionPath) {
    this.collectionPath = collectionPath;
  }

  async getDocuments(
    queryConstraints = [],
    realTime = false,
    onUpdate = null,
    limitCount = null,
    startAfterDoc = null
  ) {
    const constraints = [...queryConstraints];
    if (limitCount) {
      constraints.push(limit(limitCount));
    }
    if (startAfterDoc) {
      constraints.push(startAfter(startAfterDoc));
    }
    const q = query(collection(db, this.collectionPath), ...constraints);

    if (realTime) {
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          console.log("updated");
          const updatedDocuments = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (onUpdate) {
            if (limitCount) {
              onUpdate(
                updatedDocuments,
                snapshot.docs[snapshot.docs.length - 1],
                snapshot.docs.length < limitCount
              );
            } else {
              onUpdate(updatedDocuments);
            }
          }
        },
        (error) => {
          console.error("Error fetching documents:", error);
          throw error;
        }
      );
      return unsubscribe;
    } else {
      try {
        const snapshot = await getDocs(q);
        const documents = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (limitCount) {
          return {
            documents,
            lastVisible: snapshot.docs[snapshot.docs.length - 1],
            no_more_data: snapshot.docs.length < limitCount,
          };
        } else {
          return documents;
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
      }
    }
  }

  async getDocument(docId, onUpdate = null, listen = false) {
    const docRef = doc(db, this.collectionPath, docId);

    if (listen) {
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          onUpdate({ id: doc.id, ...doc.data() });
        } else {
          console.log(new Error("No such document!"));
        }
      });

      return () => unsubscribe();
    } else {
      try {
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          return { id: docSnapshot.id, ...docSnapshot.data() };
        } else {
          throw new Error("Document does not exist");
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }

  async addDocument(newDoc) {
    try {
      const docRef = await addDoc(collection(db, this.collectionPath), newDoc);
      return docRef;
    } catch (error) {
      console.error("Error adding document:", error);
      throw error;
    }
  }

  async deleteDocument(id = null, queryConstraints = []) {
    try {
      if (id) {
        await deleteDoc(doc(db, this.collectionPath, id));
      } else if (queryConstraints.length > 0) {
        const q = query(
          collection(db, this.collectionPath),
          ...queryConstraints
        );
        const snapshot = await getDocs(q);
        const batch = writeBatch(db);

        snapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
      } else {
        throw new Error("Either an ID or query constraints must be provided.");
      }
    } catch (error) {
      console.error("Error deleting documents:", error);
      throw error;
    }
  }

  async updateDocument(id, updates, docs = []) {
    try {
      const docRef = doc(db, this.collectionPath, id);
      await setDoc(docRef, updates, { merge: true });
      const _docs = [...docs];
      if (Array.isArray(_docs) && _docs.length > 0) {
        const index = _docs.findIndex((doc) => doc.id === id);
        if (index !== -1) {
          _docs[index] = { ..._docs[index], ...updates };
        }
      }
      return _docs;
    } catch (error) {
      console.error("Error updating document:", error);
      throw error;
    }
  }
}

export default FirestoreService;
