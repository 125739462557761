import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Modal,
  ModalDialog,
  ModalOverflow,
  Button,
  Stack,
  CircularProgress,
} from "@mui/joy";
import PropTypes from "prop-types";
import EventModels from "containers/event_templates/views/EventTemplateList/partials/EventModels/EventModels";
import { AlgoliaContainer } from "Components/algolia";
import { useParams, useHistory } from "react-router-dom";
import Event from "models/events";
import { Add } from "@mui/icons-material";
import useAuthContainer from "stores/Auth";
import User from "models/user";
import { arrayUnion } from "firebase/firestore";

const EntityEvent = new Event();
const UserEntity = new User();
const NewProgramModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuthContainer.useContainer();
  const { projectID } = useParams();
  const [creating, setCreating] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const createEventProgram = async () => {
    setCreating(true);
    try {
      EntityEvent.event_name = "Nouveau programme";
      EntityEvent.created_at = new Date();
      EntityEvent.event_description = "Description";
      EntityEvent.project_id = projectID;
      EntityEvent.uid = user.uid;

      const addedEvent = await EntityEvent.addEvent();

      history.push(`/event/${addedEvent.id}/general`);
    } catch (e) {
      console.log(e.message);
    }
    setCreating(false);
  };

  const handleFromProject = async (hit) => {
    setSaving(true);
    try {
      const EventEntity = new Event();
      const added = await EventEntity.duplicateEvent(
        `${hit.event_name} - copie`,
        hit.objectID,
        ["objectID", "id", "shared", "placeId"],
        user.uid,
        projectID
      );

      Object.assign(UserEntity, {
        accessiblePlacesMedias: arrayUnion(hit.placeId),
      });

      if (added?.id) {
        handleClose();
        await UserEntity.updateUser(user.uid);

        history.push(`/event/${added?.id}/general`);
      }
    } catch (e) {
      console.log(e.message);
    }
    setSaving(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog>
          {saving && (
            <Box
              sx={{
                background: "#FFFFFF85",
                position: "fixed",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              p: 2,
              border: "1px solid #E0E0E0",
              borderRadius: 2,
              backgroundColor: "#FFF",
            }}
          >
            <Typography sx={{ mb: 1 }} variant="h6">
              {t("create_an_event_from_scratch")}
            </Typography>
            <Button
              disabled={creating}
              loading={creating}
              onClick={createEventProgram}
              sx={{ mb: 2 }}
            >
              <Stack direction="row">
                <Add />
                <Typography sx={{ color: "#FFF" }}>
                  {t("add_a_event_program")}
                </Typography>
              </Stack>
            </Button>
          </Box>
          <AlgoliaContainer
            filters="placeId != 0 AND shared:true"
            index="events"
          >
            <EventModels handleFromProject={handleFromProject} />
          </AlgoliaContainer>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default NewProgramModal;
NewProgramModal.propTypes = {};
NewProgramModal.defaultProps = {};
