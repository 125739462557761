import FirestoreService from "services/firestoreServices";
import {
  serverTimestamp,
  orderBy,
  where,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "utils/MyFirebase"; // Import Firestore DB

class Chat {
  constructor(params) {
    Object.assign(this, params);
    this.created_at = this.created_at || new Date();
    this.modified_at = new Date();
    this.firestoreService = new FirestoreService("chats");
  }

  /**
   * Récupérer un chat spécifique par projectId
   */
  async getChat(id) {
    try {
      return await this.firestoreService.getDocument(id);
    } catch (error) {
      console.error("Failed to fetch chat:", error);
      throw error;
    }
  }

  /**
   * Récupérer tous les chats d’un utilisateur en temps réel (ordre décroissant)
   */
  async getChat(projectId) {
    try {
      const chatRef = doc(db, "chats", projectId);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) {
        console.log("Chat introuvable pour le projet :", projectId);
        return null; // Retourne null au lieu de lever une erreur
      }

      return { id: chatDoc.id, ...chatDoc.data() };
    } catch (error) {
      console.error("Erreur lors de la récupération du chat :", error);
      return null; // Retourne null en cas d'erreur
    }
  }

  async getAllChats(onUpdate) {
    try {
      return await this.firestoreService.getDocuments(
        [orderBy("lastMessageTimestamp", "desc")], // Trier par dernier message
        true, // Écoute en temps réel
        onUpdate
      );
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des conversations :",
        error
      );
      throw error;
    }
  }

  /**
   * Créer un chat entre un créateur de projet et un weever
   */
  async createChat() {
    try {
      if (!this.projectId) {
        throw new Error("projectId est indéfini lors de la création du chat");
      }

      const chatRef = doc(db, "chats", this.projectId); // Utilisation correcte de Firestore
      await setDoc(chatRef, this.toJSON(), { merge: true });

      return { id: this.projectId, ...this.toJSON() };
    } catch (error) {
      console.error("Erreur lors de la création du chat :", error);
      throw error;
    }
  }

  /**
   * Mettre à jour les informations du chat
   */
  async updateChat() {
    try {
      return await this.firestoreService.updateDocument(this.projectId, {
        ...this.toJSON(),
        modified_at: serverTimestamp(),
      });
    } catch (error) {
      console.error("Failed to update chat:", error);
      throw error;
    }
  }

  /**
   * Supprimer un chat par projectId
   */
  async deleteChat() {
    try {
      await this.firestoreService.deleteDocument(this.projectId);
    } catch (error) {
      console.error("Failed to delete chat:", error);
      throw error;
    }
  }

  /**
   * Convertir l'objet Chat en JSON (seulement les champs définis)
   */
  toJSON() {
    let data = {};
    const properties = {
      projectId: this.projectId,
      participants: this.participants,
      lastMessage: this.lastMessage,
      lastMessageTimestamp: this.lastMessageTimestamp,
      created_at: this.created_at,
      modified_at: this.modified_at,
      projectName: this.projectName,
    };

    for (let key in properties) {
      if (properties[key] !== undefined) {
        data[key] = properties[key];
      }
    }

    return data;
  }
}

export default Chat;
