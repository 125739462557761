import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Input,
  Button,
  Stack,
  Typography,
  Card,
  CircularProgress,
} from "@mui/joy";
import Chat from "models/chats";
import Project from "models/projects";
import ChatMessage from "models/chatMessage";
import { useParams } from "react-router-dom";
import Helpers from "utils/Helpers";

const ProjectChat = ({ userId, userName, projectCreatorId, weeverId }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { projectID } = useParams();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const messageEndRef = useRef(null);
  const convertDate = (d) => Helpers.fbDate(d, "DD/MM/yyyy HH:mm:ss");

  const chatService = new Chat({ projectId: projectID });
  const chatMessageService = new ChatMessage({ projectId: projectID });

  /**
   * Vérifie si le projet existe
   */
  const fetchProject = async () => {
    try {
      const projectEntity = new Project();
      const projectData = await projectEntity.getProject(projectID);

      if (!projectData) {
        console.error("❌ Projet introuvable :", projectID);
        return null;
      }

      console.log("✅ Projet trouvé :", projectData);
      setProject(projectData);
      return projectData;
    } catch (error) {
      console.error("⚠️ Erreur lors de la récupération du projet :", error);
      return null;
    }
  };

  useEffect(() => {
    if (!projectID) {
      console.log("❌ projectID est indéfini, annulation du chargement.");
      return;
    }

    const initializeChat = async () => {
      console.log("🔍 Vérification du projet avant d'initialiser le chat...");

      const projectData = await fetchProject(); // Récupération du projet

      if (!projectData) {
        console.log(
          "❌ Impossible d'initialiser le chat : projet introuvable."
        );
        return;
      }

      console.log("✅ Initialisation du chat pour le projet :", projectID);

      try {
        const existingChat = await chatService.getChat(projectID);

        if (!existingChat) {
          console.log("💬 Chat non trouvé, création en cours...");

          const newChat = new Chat({
            projectName: projectData.project_name,
            projectId: projectID,
            participants: [projectCreatorId, weeverId],
            lastMessage: "",
            lastMessageTimestamp: null,
            created_at: new Date(),
          });

          await newChat.createChat();
          console.log("✅ Chat créé avec succès !");
        } else {
          console.log("🔄 Chat déjà existant :", existingChat);
        }

        // Écoute des messages après la création du chat
        const unsubscribe = await chatMessageService.getMessages(
          (newMessages) => {
            setMessages(newMessages);
            setLoading(false);
            scrollToBottom();
          }
        );

        return () => {
          if (typeof unsubscribe === "function") {
            unsubscribe();
          }
        };
      } catch (error) {
        console.error("⚠️ Erreur lors de l'initialisation du chat :", error);
      }
    };

    initializeChat();
  }, [projectID]);

  /**
   * Fonction pour scroller en bas automatiquement lorsqu'un nouveau message est ajouté
   */
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * Gestion de l'envoi des messages
   */
  const handleSendMessage = async () => {
    if (message.trim() === "") return;

    console.log("📩 Envoi du message avec projectID :", projectID);

    const newMessage = new ChatMessage({
      projectId: projectID,
      senderId: userId,
      senderName: userName,
      text: message,
    });

    await newMessage.sendMessage();
    setMessage("");
    scrollToBottom();
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "100%",
        padding: 2,
        boxShadow: "lg",
        height: "100%",
      }}
    >
      <Typography level="h4" sx={{ marginBottom: 2 }}>
        💬 Chat du projet
      </Typography>
      {project ? (
        <Typography level="body-sm" sx={{ marginBottom: 2 }}>
          Échangez avec{" "}
          <b>
            {project?.weever?.lastname} {project?.weever?.name}
          </b>
        </Typography>
      ) : (
        <Typography level="body-sm" sx={{ marginBottom: 2, color: "error" }}>
          ❌ Projet introuvable !
        </Typography>
      )}

      {/* Chargement */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress size="md" />
        </Box>
      )}

      {/* Liste des messages (ordre inversé) */}
      <Box
        sx={{
          maxHeight: 300,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse", // Inverse l'ordre d'affichage
          gap: 1,
          padding: 1,
          backgroundColor: "neutral.softBg",
          borderRadius: "sm",
        }}
      >
        <div ref={messageEndRef} />
        {messages.length > 0 ? (
          messages.map((msg) => (
            <Stack
              key={msg.id}
              direction="row"
              spacing={1}
              sx={{
                justifyContent:
                  msg.senderId === userId ? "flex-end" : "flex-start",
              }}
            >
              <Box
                sx={{
                  maxWidth: "75%",
                  padding: 1,
                  backgroundColor:
                    msg.senderId === userId
                      ? "primary.softBg"
                      : "neutral.outlinedBorder",
                  borderRadius: "lg",
                  textAlign: msg.senderId === userId ? "right" : "left",
                }}
              >
                <Typography
                  level="caption"
                  sx={{ fontSize: "10px", color: "#888" }}
                >
                  {convertDate(msg.created_at)}
                </Typography>
                <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                  {msg.senderId === userId ? "Moi" : msg.senderName}
                </Typography>
                <Typography level="body-sm">{msg.text}</Typography>
              </Box>
            </Stack>
          ))
        ) : (
          <Typography
            level="body-sm"
            sx={{ textAlign: "center", color: "neutral.outlinedColor" }}
          >
            Aucun message pour le moment...
          </Typography>
        )}
      </Box>

      {/* Zone de saisie */}
      <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
        <Input
          placeholder="Écrire un message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button onClick={handleSendMessage} variant="solid" disabled={!project}>
          Envoyer
        </Button>
      </Stack>
    </Card>
  );
};

export default ProjectChat;
