import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/joy";
import PropTypes from "prop-types";
import { BOLayout } from "../../../../components/layouts";
import { useParams } from "react-router-dom";
import useAuthContainer from "stores/Auth";
import { ProjectChat } from "../../partials";
import Project from "models/projects";

const ProjectEntity = new Project();
const Chat = () => {
  const { t } = useTranslation();
  const { projectID } = useParams();
  const { userAccount, user } = useAuthContainer.useContainer();
  const [project, setProject] = React.useState(null);

  const fetchProject = async () => {
    try {
      const project = await ProjectEntity.getProject(projectID);
      if (project) {
        setProject(project);
      }
    } catch (e) {
      console.error("fetching project error", e.message);
    }
  };

  React.useEffect(() => {
    fetchProject();
  }, [projectID]);
  return (
    <BOLayout
      documentID={projectID}
      isDocumentStatusMonitoring
      showProjectMenu
      title="project_chat"
    >
      <Box>
        {user && project && project?.weever?.uid ? (
          <ProjectChat
            projectId={projectID}
            userId={user?.uid}
            userName={user?.additionalDatas?.name}
            projectCreatorId={project?.uid}
            weeverId={project?.weever?.uid}
          />
        ) : (
          t("no_weever_assigned")
        )}
      </Box>
    </BOLayout>
  );
};

export default Chat;
Chat.propTypes = {};
Chat.defaultProps = {};
