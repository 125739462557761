import React from "react";
import { Switch, Route } from "react-router-dom";
import { Chat, NewProject, ListEvent, ProjectDashboard } from "./views";

const ProjectRoutes = () => {
  return (
    <Switch>
      <Route exact path="/projects">
        <ListEvent />
      </Route>

      <Route exact path="/projects/create">
        <NewProject />
      </Route>

      <Route path="/projects/:projectID/chat">
        <Chat />
      </Route>

      <Route exact path="/projects/:projectID/dashboard">
        <ProjectDashboard />
      </Route>

      <Route exact path="/projects/:projectID">
        <ProjectDashboard />
      </Route>
    </Switch>
  );
};

export default ProjectRoutes;
