import React from "react";
import { createGlobalStyle } from "styled-components";
import AppRoutes from "./AppRoutes";
import { Routes, Route, NavLink } from "react-router-dom";
import useAuthContainer, { withAuthContainer } from "./stores/Auth";
import ModulesAccess from "./ModulesAccess";
import MediaLibrary from "./components/common/MediaLibrary/MediaLibrary";
import { Snackbar, Stack } from "@mui/joy";
import { Typography } from "antd";

const App = (props) => {
  const { notification } = useAuthContainer.useContainer();

  return (
    <>
      {notification && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          color="primary"
          variant="outlined"
        >
          <Stack direction="column">
            <Typography sx={{ color: "#FFFFFF", fontSize: "18px" }}>
              {notification?.notification.title || "title"}
            </Typography>
            <Typography sx={{ color: "#FFFFFF", fontSize: "12px" }} paragraph>
              {notification?.notification.body || "body"}
            </Typography>
          </Stack>
        </Snackbar>
      )}
      <MediaLibrary />
      <GlobalStyle />

      <AppRoutes />
    </>
  );
};

export default withAuthContainer(App);

const GlobalStyle = createGlobalStyle`

body,
html {

  min-height: 100%;
  margin: 0;
  padding: 0;
}

div#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

  `;
