import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/joy";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { People, Timelapse, Place } from "@mui/icons-material";
import { Hits } from "react-instantsearch";
import { PlacesTemplateCard } from "UI/Grids";
import { AddToFavorite } from "Common/favorites";
import useEventTemplatesStore from "../../../../../../EventTemplatesStore";

const PlaceCardHit = ({
  select,
  t,
  event_name,
  event_description,
  event_background_image,
  event_participants_qty_min,
  event_participants_qty_max,
  nights_duration,
  days_duration,
  objectID,
  pays,
  region,
}) => (
  <Box
    sx={{
      "&:hover": {
        ".overlay": {
          display: "flex",
        },
      },
      height: "100%",
      width: "100%",
      position: "relative",
    }}
  >
    <Box
      sx={{
        height: 150,
        width: "100%",
        background: `url("${
          event_background_image?.thumb ||
          event_background_image?.full ||
          event_background_image
        }")no-repeat  center center / cover`,
      }}
    ></Box>
    <Stack sx={{ p: 2 }} spacing={1}>
      <Typography
        sx={{
          fontSize: "19px",
          width: "90%",
          whiteSpace: "pre",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {event_name}
      </Typography>
      <List>
        <ListItem>
          <Place />
          <Typography>
            {region && region && pays ? `${region}, ` : region}
            {pays && `${pays}`}
          </Typography>
        </ListItem>
        <ListItem>
          <People />
          <Typography>
            {event_participants_qty_min &&
              event_participants_qty_max &&
              t("between_xandx_participants", {
                min: event_participants_qty_min,
                max: event_participants_qty_max,
              })}
            {event_participants_qty_min &&
              !event_participants_qty_max &&
              t("from_x_participants", {
                min: event_participants_qty_min,
              })}
            {!event_participants_qty_min &&
              event_participants_qty_max &&
              t("until_x_participants", {
                max: event_participants_qty_max,
              })}
          </Typography>
        </ListItem>
        {(nights_duration || days_duration) && (
          <ListItem>
            <Timelapse />
            {days_duration && t("days_duration_x", { nbr: days_duration })}{" "}
            {nights_duration &&
              t("nights_duration_x", {
                nbr: nights_duration,
              })}
          </ListItem>
        )}
      </List>
    </Stack>

    <Box
      className="overlay"
      sx={{
        transition: "ease 200ms",
        background: "#FFFFFF98",
        display: "none",
        top: 0,
        left: 0,
        zIndex: 3,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <Stack spacing={3}>
        <Button onClick={select} variant="solid" color="primary">
          {t("use_this_template")}
        </Button>
        <Button
          component={Link}
          target="_blank"
          to={`/my_event/${objectID}`}
          variant="solid"
          color="neutral"
        >
          {t("visualize")}
        </Button>
      </Stack>
    </Box>
  </Box>
);

const Results = ({ showMap, handleFromProject }) => {
  const { t } = useTranslation();
  const setSelectedEvent = !handleFromProject
    ? useEventTemplatesStore.useContainer().setSelectedEvent
    : null;
  return (
    <>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          p: 2,
          ".ais-Hits": {},
          ".ais-Hits-list": {
            display: "grid",
            gap: 2,
            gridTemplateColumns: !showMap
              ? {
                  md: "1fr 1fr 1fr 1fr",
                  sm: "1fr 1fr",
                }
              : {
                  lg: "1fr 1fr",
                  xl: "1fr 1fr 1fr",
                },
            ".ais-Hits-item": {
              height: "350px",
              p: 0,
              display: "flex",
              overflow: "hidden",
              alignItems: "start",

              width: "100%",
            },
          },
        }}
      >
        <Hits
          hitComponent={({ hit }) => (
            <PlaceCardHit
              select={() => {
                setSelectedEvent
                  ? setSelectedEvent(hit)
                  : handleFromProject(hit);
              }}
              key={hit.objectID}
              t={t}
              {...hit}
            />
          )}
        />
      </Box>
    </>
  );
};

export default Results;
Results.propTypes = {
  showMap: PropTypes.bool,
};
Results.defaultProps = {};
