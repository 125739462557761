import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { BOLayout } from "Components/layouts";
import AdminRoutes from "./AdminRoutes";
import useAuthContainer from "stores/Auth";

const Admin = () => {
  const location = useLocation();
  const title = location.pathname.split("/")[2] || "admin";
  const { userAccount, user } = useAuthContainer.useContainer();
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    // Ne pas rediriger tant que `userAccount` n'est pas complètement chargé
    if (userAccount === null) return;

    if (user && userAccount?.role !== "admin") {
      history.replace("/");
      alert(t("not_authorize_to_acces_admin"));
    }
  }, [userAccount, user, history, t]);

  // Afficher un écran de chargement tant que les données ne sont pas disponibles
  if (userAccount === null || user === false) {
    return <div>Chargement...</div>; // Personnalisez ce message si nécessaire
  }

  return (
    <BOLayout admin title={title}>
      <AdminRoutes />
    </BOLayout>
  );
};

export default Admin;
