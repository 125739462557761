import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, IconButton } from "@mui/joy";
import { Delete } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import Project from "models/projects";
import { OptionItemCard, OptionModal } from "UI";

const ProjectEntity = new Project();

const TeamBuildingsList = ({ setDatas, datas, project_id }) => {
  const { t } = useTranslation();
  const team_buildings = datas?.project_team_buildings || [];
  const [displayDetails, setDisplayDetails] = React.useState(null);

  const HandleDelete = (id) => {
    setDatas((prev) => {
      const newDatas = prev?.project_team_buildings?.filter((d) => d.id !== id);
      ProjectEntity.project_team_buildings = newDatas;
      ProjectEntity.updateProject(project_id);
      return { ...prev, project_team_buildings: newDatas };
    });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          flexWrap: "wrap",
          mt: 2,
        }}
      >
        {team_buildings.map((team_building) => (
          <OptionItemCard
            id={team_building.id}
            title={team_building?.team_building_name}
            description={`${team_building?.team_building_description} \n ${team_building?.team_building_rse}`}
            extraInfo={team_building?.team_building_price}
            image={team_building?.team_building_featured_image}
            onDelete={(id) => HandleDelete(team_building.id)}
            showDetails={() => setDisplayDetails(team_building)}
          />
        ))}
      </Box>

      <OptionModal
        open={Boolean(displayDetails)}
        onClose={() => setDisplayDetails(false)}
        title={displayDetails?.team_building_name}
        description={displayDetails?.team_building_description}
        price={displayDetails?.team_building_price}
        image={displayDetails?.team_building_featured_image}
      />
    </>
  );
};

export default TeamBuildingsList;
TeamBuildingsList.propTypes = {};
TeamBuildingsList.defaultProps = {};
